import HISTORICAL_ACCURACY from "../data/historical_accuracy.json";

/*
    Historical Analysis Domain Data
     - this could be generated to match python
*/
export const PredictionBucket = {
    NoBucket:-1,
    Zero:0,
    One:1,
    Two:2,
    Three:3,
    Four:4,
    Five:5,
    Six:6,
    Seven:7,
    EightToNine:8,
    TenToTwelve:9,
    ThirteenToFifteen:10,
    SixteenToNineteen:11,
    TwentyPlus:12
}

//return all the values of the enum as a list
export const PredictionBucketList = () => {
    return Object.values(PredictionBucket).filter(b=>b!==PredictionBucket.NoBucket);
}

//given a PredictionBucket value (ultimately an int), return the display name for that bucket
export function bucketDisplayName(b){
    switch (parseInt(b)){
        case PredictionBucket.NoBucket: return "NoBucket"
        case PredictionBucket.Zero: return "0"
        case PredictionBucket.One: return "1"
        case PredictionBucket.Two: return "2"
        case PredictionBucket.Three: return "3"
        case PredictionBucket.Four: return "4"
        case PredictionBucket.Five: return "5"
        case PredictionBucket.Six: return "6"
        case PredictionBucket.Seven: return "7"
        case PredictionBucket.EightToNine: return "8-9"
        case PredictionBucket.TenToTwelve: return "10-12"
        case PredictionBucket.ThirteenToFifteen: return "13-15"
        case PredictionBucket.SixteenToNineteen: return "16-19"
        case PredictionBucket.TwentyPlus: return "20+"
        default: return ""
    }
}
export function bucketFromDisplayName(display_name){
    switch (display_name){
        case "NoBucket": return PredictionBucket.NoBucket
        case "0": return PredictionBucket.Zero
        case "1": return PredictionBucket.One
        case "2": return PredictionBucket.Two
        case "3": return PredictionBucket.Three
        case "4": return PredictionBucket.Four
        case "5": return PredictionBucket.Five
        case "6": return PredictionBucket.Six
        case "7": return PredictionBucket.Seven
        case "8-9": return PredictionBucket.EightToNine
        case "10-12": return PredictionBucket.TenToTwelve
        case "13-15": return PredictionBucket.ThirteenToFifteen
        case "16-19": return PredictionBucket.SixteenToNineteen
        case "20+": return PredictionBucket.TwentyPlus
        default: return ""
    }
}
export const resultFrequencySorter = (a, b) => {
    let x = parseInt(bucketFromDisplayName(a["actual_bucket"]));
    let y = parseInt(bucketFromDisplayName(b["actual_bucket"]));
    x = (a["win_loss"] === "W" ? 100 : 0) + (a["win_loss"] === "L" ? -1 : 1)*x;
    y = (b["win_loss"] === "W" ? 100 : 0) + (b["win_loss"] === "L" ? -1 : 1)*y;
    return x - y;
}
//given the display name of a prediction bucket, retrieve those results from the history dataset
export const getHistoricalAccurary = (prediction_bucket_key) => {
    const buckets=HISTORICAL_ACCURACY['bucket_performance'];
    return buckets[prediction_bucket_key];
}
//extract data from the history dataset about the number of games which were used in the computations
export const getHistoricalGameCounts = () => {
    return {
        'total_games':HISTORICAL_ACCURACY['total_games'],
        'eligible_games':HISTORICAL_ACCURACY['eligible_games']
    }
}

export const getWinLossByBucket = () => {
    const buckets=HISTORICAL_ACCURACY['bucket_performance'];
    let win_pct_list=Object.keys(buckets).map(b=>[bucketFromDisplayName(b), buckets[b]['W']]);
    return Object.fromEntries(win_pct_list);
    //return buckets.map(b=>)
}
// Give an historical accuracy list ({win_loss,actual_bucket,frequency})
// determine the cumulative frequency of scores falling in the target_bucket
//  or buckets above it
export const cumulativeFrequency = (result_frequencies, win_loss, target_bucket_key) => {
    const target_bucket = parseInt(bucketFromDisplayName(target_bucket_key));
    //grab all the winning or losing frequencies, depending on which matches the bucket
    return result_frequencies.filter(r => (r["win_loss"] === win_loss)
        // add each frequency to the total (or zero if the bucket is below the specified bucket}
    ).reduce(
        (accumulator, result) => accumulator + (
            (parseInt(bucketFromDisplayName(result["actual_bucket"])) >= target_bucket) ? result["frequency"] : 0.0
        )

        , 0.0);

}