import React, { useState } from "react"
import Page from "../components/page";
import ScoreBucketDropdown from "../components/scorebucketdropdown";
import { PredictionBucket, resultFrequencySorter, bucketDisplayName,cumulativeFrequency, getHistoricalAccurary, getHistoricalGameCounts, getWinLossByBucket } from "../analysis";
import { formatPct } from "../utils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableHead } from "@mui/material";
import { DEFAULT_Y_AXIS_KEY } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';

//given a prediction_bucket, display the historical accuracy panel
const HistoricalAccuracyPanel = (prediction_bucket) => {
    const bucket_key = bucketDisplayName(prediction_bucket);
    const historical_accuracy = getHistoricalAccurary(bucket_key);

    return <div className="plainText">
            <p>There are {historical_accuracy['GameCount'].toLocaleString()} games in our database where the predicted margin of victory was {bucket_key}.</p>
            <p>The team won {formatPct(historical_accuracy['W'], 2)} and lost {formatPct(historical_accuracy['L'], 2)} of those games:</p>
            {frequenciesTable(historical_accuracy)}
        </div>;
}


// Give an historical accuracy list ({win_loss,actual_bucket,frequency})
// build the table which displays them
const frequenciesTable = (historical_accuracy) => {
    return (<Table sx={{ maxWidth: "260px" }} size="small">
        <TableHead>
            <TableRow>
                <TableCell className="ratingMetadata">Result</TableCell><TableCell className="ratingMetadata">Frequency</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {historical_accuracy['ResultFrequencies'].sort(
                (a, b) => resultFrequencySorter(a, b)).map(r =>
                    <TableRow key={`${r["win_loss"]}${r["actual_bucket"]}`}>
                        <TableCell className="rating">{(r["win_loss"] === "W") ? "Win By " : "Lose By "}{" at least "}{r["actual_bucket"]}</TableCell>
                        <TableCell className="rating">{formatPct(cumulativeFrequency(historical_accuracy['ResultFrequencies'], r["win_loss"], r["actual_bucket"]), 2)}</TableCell>
                    </TableRow>)}
        </TableBody>
    </Table>);
}

const WinPctChart = () => {
    const winloss = getWinLossByBucket();
    const chartdata = Object.keys(winloss).map(x => parseInt(x));
    const chartseriesvals = Object.values(winloss);
    const series = { data: chartseriesvals, label: "Win %", color: '#4e79a7' };
    const xaxis = {
        data: chartdata,
        tickNumber: chartdata.length,
        label: "Predicted Margin of Victory",
        valueFormatter: (b) => bucketDisplayName(b),
        tickLabelStyle: { angle: 0 }
    };
    return <div style={{ textAlign: "center" }}>
        <h2>Win % by Predicted Margin of Victory</h2>
        <LineChart sx={{ backgroundColor: "#f1f8e9" }}
            xAxis={[xaxis]}
            series={[series]}
            width={560}
            height={300}
            leftAxis={null}
            rightAxis={{ axisId: DEFAULT_Y_AXIS_KEY, valueFormatter: (p) => formatPct(p) }}
            slotProps={{
                legend: {
                    position: {
                        vertical: 'middle',
                        horizontal: 'left'
                    }
                }
            }}
        />
    </div>
}
const PerformancePage = (props) => {
    const [selectedBucket, selectBucket] = useState(PredictionBucket.One);
    const gameCounts = getHistoricalGameCounts();
    const pageTitle="Historical Accurracy";
    return (
        <Page location={props.location.href} pageTitle={pageTitle}>
        <h1>{pageTitle}</h1>
            <p>
                We've used the {gameCounts['total_games'].toLocaleString()} games in our database, dating back to 2015, to compute the likelihood that a game would end with certain score margins based on the predicted score margin.
                Note that of these games, we only use the {gameCounts['eligible_games'].toLocaleString()} games from January through March.
            </p>
            <Table width="100%" sx={{border:0}} >
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ padding:0}} className="plainText">
                            <Table  sx={{border:0}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="plainText" sx={{ padding: 0,border:0 }}>
                                            Select a margin of victory to see how teams have fared historically when the model would have predicted that margin:
                                        </TableCell>
                                        <TableCell sx={{ padding: 0,border:0 }}>
                                            <ScoreBucketDropdown onSelectedBucketChanged={(c) => selectBucket(c)} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br />
                            {HistoricalAccuracyPanel(selectedBucket)}
                        </TableCell>

                   
                        <TableCell sx={{ padding: 0,border:0 }} style={{ verticalAlign: 'top' }}>
                                {WinPctChart()}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Page>
    );
};
export default PerformancePage;