import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'; //https://material-ui.com/components/autocomplete/
import { PredictionBucket, PredictionBucketList, bucketDisplayName } from "../analysis";

export default class ScoreBucketDropdown extends React.Component {
    state = {
      selectedBucket: this.getDefault()
    }
    selectionChanged = (event,newValue) =>  {
        this.setState({selectedBucket:newValue})
        this.props.onSelectedBucketChanged(newValue) //pass it up to container
    }
    getDefault() {
      const defaultBucket = PredictionBucket.One;
      return PredictionBucketList().find((bucket)=>bucket===defaultBucket)
    }
   
    render() {
        return (
            <Autocomplete sx={{maxWidth:"80px"}}  disableClearable={true}
              id="bucketSelect"
              value={this.state.selectedBucket}
              options={PredictionBucketList()}
              getOptionLabel={(bucket) => bucketDisplayName(bucket)}
              onChange={this.selectionChanged}
              renderInput={(params) => <TextField {...params} label="Score Margin" variant="outlined" />}
            />
        );
    }
  

  }